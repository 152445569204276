import EventIcon from '@mui/icons-material/Event';
import { Grid } from '@mui/material';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { config } from 'src/app/constants/config/config';
import { useFetchTeams } from 'src/app/hooks/teams/useFetchTeams';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import FilterOption from 'src/data/api/common/FilterOption';
import { STATUSES } from 'src/data/models/events/event';
import { dateFormatYearMonthDay } from 'src/shared/date';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import {
    FilterAutoCompleteOption,
    FilterAutoCompleteOptions,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import { FilteredOnNotification } from 'src/view/components/filters/FilteredOnNotification/FilteredOnNotification';

export interface EventsFilterFormValues {
    series?: FilterAutoCompleteOptions;
    organizers?: FilterAutoCompleteOptions;
    seatingPlan?: FilterAutoCompleteOption | null;
    startDate?: Date | null;
    endDate?: Date | null;
    searchTerm?: string;
    teamId?: string;
    organizerId?: string;
    status?: FilterAutoCompleteOption | null;
}

interface Props {
    defaultValues: EventsFilterFormValues;
    seriesOptions: FilterAutoCompleteOptions;
    organizerOptions: FilterAutoCompleteOptions;
    seatingPlanOptions: FilterAutoCompleteOptions;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
}

export default function EventsFilterForm({
    defaultValues,
    seriesOptions,
    organizerOptions,
    seatingPlanOptions,
    onChangeFilterOptions,
    onChangeSearchTerm,
}: Props): JSX.Element {
    const spacingClasses = useSpacingStyles();

    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const form = useForm<EventsFilterFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { watch, setValue, control, reset } = form;

    const series = watch('series');
    const organizers = watch('organizers');
    const seatingPlan = watch('seatingPlan');
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const searchTerm = watch('searchTerm');
    const teamId = watch('teamId');
    const status = watch('status');

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
        if (seatingPlanOptions.length !== 1 || (organizers && organizers.length <= 0)) return;

        if (organizers && organizers.length > 1 && defaultValues.seatingPlan) {
            setValue('seatingPlan', null);
        } else {
            setValue('seatingPlan', seatingPlanOptions[0]);
        }

        setUrlInvalidatedAt(Date.now());
        // JSON.stringify is a temporary fix
        // the problem is actually in the EventsFilterFormDataWrapper where reference of the array changes using map
        // it creates a new array with the same content each time
    }, [JSON.stringify(seatingPlanOptions), organizers?.length]);

    const teamIdFilter = formatValuesToParams.formatOptionToParam('teamId', { teamId });

    const { data } = useFetchTeams({
        filter: teamIdFilter ? [teamIdFilter] : [],
        pageSize: config.ITEMS_PER_PAGE_STANDARD,
    });

    useEffect(() => {
        if (!onChangeFilterOptions || !urlInvalidatedAt) return;

        const ticketsFilter = _.compact([
            formatValuesToParams.formatOptionsToParam('seriesId', series),
            formatValuesToParams.formatOptionsToParam('organizerId', organizers),
            formatValuesToParams.formatOptionToParam('seatingPlan', seatingPlan),
            formatValuesToParams.formatOptionToParam('status', status),
            teamIdFilter,
            startDate
                ? formatValuesToParams.formatDateToParam(
                      'dateTimeStart',
                      moment(startDate).format(dateFormatYearMonthDay),
                      'afterDate'
                  )
                : undefined,
            endDate
                ? formatValuesToParams.formatDateToParam(
                      'dateTimeStart',
                      moment(endDate).format(dateFormatYearMonthDay),
                      'beforeDate'
                  )
                : undefined,
        ]).filter((f) => f.value !== undefined && f.value !== '');

        onChangeFilterOptions(ticketsFilter);
    }, [urlInvalidatedAt]);

    const hasMoreThanOneOrganizers = organizers && organizers.length >= 2;

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });

        onChangeSearchTerm?.(value);
    };

    const datePickerOnChange = (value: Moment | null, field: 'startDate' | 'endDate'): void => {
        if (value === null) {
            setValue(field, null, {
                shouldValidate: true,
            });
            return;
        }

        setValue(field, value.toDate(), {
            shouldValidate: true,
        });
    };

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: seriesOptions,
            name: 'series',
            filterPlaceholderProps: {
                selectedText: 'Series',
                placeholder: 'Series',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        {
            type: 'autocomplete',
            options: organizerOptions,
            name: 'organizers',
            filterPlaceholderProps: {
                selectedText: 'Organizers',
                placeholder: 'Organizer',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        {
            type: 'autocomplete',
            options: seatingPlanOptions,
            name: 'seatingPlan',
            filterPlaceholderProps: {
                selectedText: 'Seating Plan',
                placeholder: 'Seating Plan',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            disabled: hasMoreThanOneOrganizers,
        },
        {
            name: 'status',
            type: 'autocomplete',
            options: STATUSES.map((status) => ({ label: status, value: status })),
            filterPlaceholderProps: {
                placeholder: 'Status',
                selectedText: 'Status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            name: 'startDate',
            onChange: (date) => {
                if (date === null) {
                    datePickerOnChange(moment(), 'startDate');
                } else {
                    datePickerOnChange(date, 'startDate');
                }

                if (moment(date).isValid() || date === null) setUrlInvalidatedAt(Date.now());
            },
            type: 'datepicker',
            filterPlaceholderProps: {
                startIcon: <EventIcon />,
                placeholder: 'From',
                keepPlaceholder: true,
                hideDeleteButton: moment(startDate).isSame(moment(), 'day'),
            },
        },
        {
            name: 'endDate',
            onChange: (date) => {
                datePickerOnChange(date, 'endDate');
                if (moment(date).isValid() || date === null) setUrlInvalidatedAt(Date.now());
            },
            type: 'datepicker',
            filterPlaceholderProps: {
                startIcon: <EventIcon />,
                placeholder: 'To',
                keepPlaceholder: true,
            },
            minDate: moment(startDate),
        },
        {
            name: 'searchTerm',
            onChange: onInputChange,
            type: 'search',
            searchPlaceholder: 'Search on event name',
            searchDefaultValue: searchTerm,
        },
    ];

    return (
        <Grid container>
            <Filters control={control} filters={arrayOfFilters} />

            {teamId && (
                <Grid item xs={12} className={spacingClasses.spacingTop}>
                    <FilteredOnNotification
                        label="team"
                        value={data?.data.data.find(({ id }) => id === teamId)?.name || ''}
                        onClickCancel={() => {
                            setValue('teamId', undefined);
                            setUrlInvalidatedAt(Date.now());
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}
