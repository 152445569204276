import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, List, ListItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useFetchEventTicketSalesCaps } from 'src/app/hooks/sales-caps/useFetchTicketSalesCaps';
import useQueryParams from 'src/app/hooks/useQueryParams';
import { EventTicketsSalesCap } from 'src/data/models/events/event';
import Popover from 'src/view/components/popover/Popover';
import Table from 'src/view/components/table/table/Table';
import { NewTableColumn, TABLE_CELL_WIDTH } from 'src/view/components/table/table/Types';
import HtmlListTooltip from 'src/view/components/tooltip/HtmlListTooltip';
import { WarningText } from 'src/view/components/warning-text/WarningText';
import EventDetailsFilterFeature, {
    useEventDetailsFilterOptions,
} from '../features/event/EventDetailsFilterFeature';

const useStyles = makeStyles((theme: Theme) => ({
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spacingRight: {
        marginRight: theme.spacing(2),
    },
    listItem: {
        width: 200,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    tscIndex: {
        marginRight: theme.spacing(2),
        fontWeight: 'bold',
    },
}));

export interface TicketSalesCapsTableProps {
    loading?: boolean;
    onEditClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    eventId: string;
}

export default function TicketSalesCapsTable({
    loading,
    onEditClick,
    onDeleteClick,
    eventId,
}: TicketSalesCapsTableProps): JSX.Element {
    const classes = useStyles();

    const {
        values: { filterOptions: ticketSalesCapFilterOptions },
        setFilterOptions: setTicketSalesCapFilterOptions,
    } = useQueryParams('ticket_sales_caps');

    const { defaultValues, seatingPlanCategoriesDropdownOptions, bookingOptionsDropdownOptions } =
        useEventDetailsFilterOptions({
            eventId,
            filterOptions: ticketSalesCapFilterOptions,
        });

    const reformed = ticketSalesCapFilterOptions.map((option) => {
        if (option.property === 'seatingPlanCategoryId') {
            return {
                ...option,
                property: 'seatingPlanCategoryIds',
            };
        }

        return option;
    });

    const { data } = useFetchEventTicketSalesCaps(
        eventId,
        {
            filter: reformed,
        },
        { enabled: !!eventId }
    );
    const eventTicketSalesCaps = data?.data?.data;
    const columns: NewTableColumn<EventTicketsSalesCap>[] = [
        {
            key: 'seatingPlanCategoryName',
            title: 'Seatingplan category',
            cellRenderer: (tsc) => (
                <span>
                    {tsc.seatingPlanCategoryName}{' '}
                    {tsc.isArchived && <WarningText text="archived" />}
                </span>
            ),
            width: TABLE_CELL_WIDTH.LARGE,
        },
        {
            key: 'bookingOptionName',
            title: 'Booking option',
            cellRenderer: ({ bookingOptionName }) => (
                <Popover text={bookingOptionName} isLink={false}>
                    <span>{bookingOptionName}</span>
                </Popover>
            ),
            width: TABLE_CELL_WIDTH.EXTRA_LARGE,
        },
        {
            key: 'salesCap',
            title: 'Sales cap',
            cellRenderer: (tsc) => tsc.salesCap,
            width: TABLE_CELL_WIDTH.MEDIUM,
        },
        {
            key: 'price',
            title: 'Price',
            cellRenderer: (tsc) => `${tsc.price.currency} ${tsc.price.value}`,
            width: TABLE_CELL_WIDTH.MEDIUM,
        },

        {
            key: 'salesProfiles',
            title: 'Sales profiles',
            cellRenderer: (tsc) => {
                if (tsc.salesProfiles.length === 0) return '';

                if (tsc.salesProfiles.length === 1) return tsc.salesProfiles[0].name;

                const tooltipData = tsc.salesProfiles.map((t, index) => (
                    <ListItem
                        divider
                        className={classNames(classes.listItem)}
                        key={`${t.name}-${index}`}
                    >
                        <span className={classes.tscIndex}>{index + 1}.</span>
                        {t.name}
                    </ListItem>
                ));

                const tooltipTitleData = <List>{tooltipData}</List>;

                return (
                    <HtmlListTooltip
                        buttonTitle={`${tsc.salesProfiles.length} platforms`}
                        title={tooltipTitleData}
                    />
                );
            },
        },
        {
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return (
                    <div className={classes.buttonsContainer}>
                        <IconButton
                            onClick={() => onEditClick(rowData.id)}
                            className={classes.spacingRight}
                            color="primary"
                            size={'small'}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => onDeleteClick(rowData.id)}
                            className={classes.spacingRight}
                            color="error"
                            size={'small'}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <EventDetailsFilterFeature
                defaultValues={defaultValues}
                onChangeFilterOptions={setTicketSalesCapFilterOptions}
                seatingPlanCategoriesDropdownOptions={seatingPlanCategoriesDropdownOptions}
                bookingOptionsDropdownOptions={bookingOptionsDropdownOptions}
            />
            <Table<EventTicketsSalesCap>
                data={eventTicketSalesCaps || []}
                rowIdResolver={(rowData) => ({
                    id: rowData.id,
                })}
                columns={columns}
                loading={loading}
                minWidth={650}
            />
        </>
    );
}
